import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layout";
import noDataImage from "../../assets/images/no-data.png";
import { ReactComponent as PieEmpty } from "../../assets/svg/empty-pie-chart.svg";
import { useNavigate } from "react-router-dom";
import LineChart from "../../components/Charts/LineChart";
import PieChart from "../../components/Charts/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { categoryScoresAction, departmentScoresAction, focusAreaAction, overviewAction, progressAction } from "../../redux1/Slices/analyticsSlice";
import { DashobardOverviewCard, FocusArea, MainCard } from "../../components/cards/card";
import CustomTable from "../../components/table/table";
import { departmentAction } from "../../redux1/Slices/departmentSlice";
import { setDepartmentsData } from "./common";
import { months } from "../../utils/constants";
import PrimaryButton, { ButtonPosition, PrimaryButtonOutlined } from "../../components/buttons/button";
import CustomSkelton from "../../components/cards/CustomSkelton";
import { Menu } from "primereact/menu";
import { CustomDropDown } from "../../components/input/input";
import CircularChart from "../../components/Charts/CircularChart";

export const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ageMenu = useRef(null);
    const genderMenu = useRef(null);
    // const progressMenu = useRef(null);
    const isLoading = useSelector((state) => state.loader.isLoading);
    const overview = useSelector((state) => state.analytics.overview);
    const categoryScores = useSelector((state) => state.analytics.categoryScores);
    const departments = useSelector((state) => state.department.data);
    const departmentScores = useSelector((state) => state.analytics.departmentScores);
    const focusArea = useSelector((state) => state.analytics.focusArea);
    const progress = useSelector((state) => state.analytics.progress);

    const [progressMenu, setProgressMenu] = useState(false);
    const [deptColumns, setDeptColumns] = useState([]);
    const [deptScores, setDeptScores] = useState([]);
    const [progressScores, setProgressScores] = useState([]);
    const [filterAge, setFilterAge] = useState("");
    const [availableCategories, setAvailableCategories] = useState([]);
    const [filterGender, setFilterGender] = useState("");
    const [progressFilterData, setProgressFilterData] = useState({
        startMonth: "",
        endMonth: "",
        year: "",
        categoryId: "",
    });

    const ageOptions = [
        { label: "18 - 25", command: () => setFilterAge("18-25") },
        { label: "25 - 30", command: () => setFilterAge("25-30") },
        { label: "30 - 40", command: () => setFilterAge("30-40") },
        { label: "40 - 50", command: () => setFilterAge("40-50") },
        { label: "> 50", command: () => setFilterAge(">50") },
    ];

    const genderOptions = [
        { label: "Male", command: () => setFilterGender("Male") },
        { label: "Female", command: () => setFilterGender("Female") },
        { label: "Other", command: () => setFilterGender("Other") },
    ];

    const handleProgressFilter = ({ name, value, e }) => {
        setProgressFilterData({ ...progressFilterData, [name]: value });
        // progressMenu.current.toggle(e);
    };

    const ProgressFilter = ({ display = "hidden" }) => {
        return (
            <div className={`w-15rem border bg-white shadow-1 z-5 right-0 top-100 absolute ${display}`}>
                <div className="border-bottom-1 border-gray-300 p-2">
                    <h3 className="m-0">Year</h3>
                    {/* <h4 className="m-0 font-medium">Select Year</h4> */}
                    <CustomDropDown
                        extraClassName="mb-2"
                        placeholder="Select Year"
                        value={progressFilterData.year}
                        name="year"
                        options={[
                            { name: 2023, value: 2023 },
                            { name: 2024, value: 2024 },
                        ]}
                        onChange={handleProgressFilter}
                    />
                </div>
                <div className="flex flex-wrap justify-content-between gap-2 border-bottom-1 border-gray-300 p-2">
                    <h3 className="m-0 w-full">Monthly</h3>
                    <div style={{ width: "48%" }}>
                        <h4 className="m-0 font-medium">Start</h4>
                        <CustomDropDown
                            extraClassName="mb-2"
                            placeholder="Select Month"
                            value={progressFilterData.startMonth}
                            name="startMonth"
                            options={months?.map((month) => ({ name: month?.fullName, value: month?.fullName }))}
                            onChange={handleProgressFilter}
                        />
                    </div>
                    <div style={{ width: "48%" }}>
                        <h4 className="m-0 font-medium">End</h4>
                        <CustomDropDown
                            extraClassName="mb-2"
                            placeholder="Select Month"
                            value={progressFilterData.endMonth}
                            name="endMonth"
                            options={months?.map((month, index) => {
                                let startMonth = progressFilterData?.start !== "" ? months.indexOf(progressFilterData?.start) : 0;
                                return { name: month?.fullName, value: month?.fullName, disabled: index < startMonth ? true : false };
                            })}
                            onChange={handleProgressFilter}
                        />
                    </div>
                </div>
                <div className="p-2">
                    <h3 className="m-0">Category</h3>
                    {/* <h4 className="m-0 font-medium">Select Category</h4> */}
                    <CustomDropDown
                        extraClassName="mb-2"
                        placeholder="Select Category"
                        value={progressFilterData.categoryId}
                        name="categoryId"
                        options={availableCategories.map((category, index) => {
                            return { name: category?.name, value: category?._id };
                        })}
                        onChange={handleProgressFilter}
                    />
                </div>
                <div className="flex align-items-center justify-content-between p-2">
                    <PrimaryButton label="Apply" onClick={applyProgressFilter} />
                    <PrimaryButton label="Clear" secondary onClick={clearProgressFilter} />
                </div>
            </div>
        );
    };

    const applyProgressFilter = () => {
        dispatch(progressAction({ age: filterAge, gender: filterGender, ...progressFilterData }));
        setProgressMenu(false);
    };

    const clearProgressFilter = () => {
        dispatch(progressAction({ age: filterAge, gender: filterGender }));
        setProgressMenu(false);
    };

    const navigateToCreateSurvey = () => {
        navigate("/surveys/create-survey");
    };

    const formatProgressScores = (progressData) => {
        if (progressData?.data === undefined) return;
        let categories = [];
        let availableCategoryList = progressData?.allCategories ? progressData?.allCategories : [];
        let scores = [];
        Object.keys(progressData?.data).forEach((item) => {
            progressData?.data[item].forEach((item) => {
                if (!categories.includes(item.categoryName)) {
                    categories.push(item.categoryName);
                    let data = {
                        category: item.categoryName,
                        color: item.color,
                        scores: months?.map((month) => ({ month: month?.fullName, count: null })),
                    };
                    scores.push(data);
                }
            });
        });

        let updatedScores = scores.map((item) => {
            let scores = item.scores.map((data) => {
                let newData = data;
                if (progressData?.data[data.month]?.length) {
                    let category = progressData?.data[data.month].filter((e) => e.categoryName === item.category);

                    newData = category.length
                        ? {
                            month: category[0].month,
                            count: category[0].overAllScore,
                        }
                        : data;
                }
                return newData;
            });

            return { ...item, scores };
        });


        setProgressScores(updatedScores);
        setAvailableCategories(availableCategoryList);
    };

    const clearFilter = () => {
        setFilterAge("");
        setFilterGender("");
    };

    useEffect(() => {
        dispatch(overviewAction({ age: filterAge, gender: filterGender }));
        dispatch(categoryScoresAction({ age: filterAge, gender: filterGender }));
        dispatch(departmentScoresAction({ age: filterAge, gender: filterGender }));
        dispatch(progressAction({ age: filterAge, gender: filterGender }));
        dispatch(focusAreaAction({}));
        dispatch(departmentAction({}));
    }, [filterAge, filterGender, dispatch]);


    console.log("departmentScores", departmentScores);
    useEffect(() => {
        setDepartmentsData(departments, departmentScores, setDeptColumns, setDeptScores);
    }, [departments,departmentScores]);

    useEffect(() => {
        if (progress) formatProgressScores(progress);
    }, [progress]);

    return (
        <DashboardLayout heading="Dashboard" label="Create Survey" onClick={navigateToCreateSurvey}>
            <Menu model={ageOptions} popup ref={ageMenu} className="filter-menu" />
            <Menu model={genderOptions} popup ref={genderMenu} className="filter-menu" />
            <div className="bg-white flex flex-wrap my-3 ">
                <div className="w-12 md:w-7  overview-area border p-3 pb-3 md:pb-0">
                    <div className="flex justify-content-end">
                        <div className="col" style={{ maxWidth: "150px" }}>
                            {filterAge !== "" || filterGender !== "" ? <PrimaryButton label="Clear Filter" className="bg-skyblue border-none" onClick={clearFilter} /> : null}
                        </div>
                        <div className="col" style={{ maxWidth: "140px" }}>
                            <PrimaryButtonOutlined label={filterAge === "" ? "Age" : filterAge} icon="pi pi-filter" iconPos="right" onClick={(event) => ageMenu.current.toggle(event)} />
                        </div>
                        <div className="col" style={{ maxWidth: "140px" }}>
                            <PrimaryButtonOutlined label={filterGender === "" ? "Gender" : filterGender} icon="pi pi-filter" iconPos="right" onClick={(event) => genderMenu.current.toggle(event)} />
                        </div>
                    </div>

                    {/* Cards */}
                    <div className="flex flex-wrap gap-3 my-3">
                        <DashobardOverviewCard title="Company score" value={`${overview?.data?.overAllHQScore}/100`} fromLastMonth={0} />
                        <DashobardOverviewCard title="Responses Rate" value={`${overview?.data?.responseRate}%`} fromLastMonth={0} />
                        <DashobardOverviewCard title="Users" value={overview?.data?.numberOfEmployees} user={true} fromLastMonth={0} />
                    </div>
                    {/* <PieChart
                                        data={categoryScores?.length ? categoryScores?.map((category) => category?.overAllScore) : []}
                                        labels={categoryScores?.length ? categoryScores?.map((category) => category?.categoryName) : []}
                                        colors={categoryScores?.length ? categoryScores?.map((category) => category?.color) : []}
                                    /> */}
                    {/* Pie Chart */}
                    <div className="border p-3 z-4 flex flex-wrap relative">
                        <div className="col-12 lg:col-6">
                            <h2 className="m-0 text-xl">Company score</h2>
                            <p>Average score of all categories.</p>
                            <div className="flex justify-content-center">
                                {isLoading ? (
                                    <CustomSkelton size="15rem" shape="circle" className="border-circle" />
                                ) : categoryScores?.length ? (
                                    <CircularChart
                                        data={categoryScores?.length ? categoryScores?.map((category) => (category?.overAllScore ? category?.overAllScore : 0)) : []}
                                        labels={categoryScores?.length ? categoryScores?.map((category) => category?.categoryName) : []}
                                        colors={categoryScores?.length ? categoryScores?.map((category) => category?.color) : []}
                                    />
                                ) : (
                                    <PieEmpty />
                                )}
                            </div>
                        </div>
                        <div className="border-none flex flex-column align-items center justify-content-center lg:border-left-1 border-400  pl-3 col-12 lg:col-6">
                            {isLoading ? (
                                <CustomSkelton width="100%" height="3rem" />
                            ) : categoryScores?.length ? (
                                categoryScores?.map((category, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex align-items-center cursor-pointer hover:shadow-1 pl-2 my-2 hover:text-cyan-500"
                                            onClick={() => navigate("/dashboard/category/" + category._id)}
                                        >
                                            <span className="border-circle mr-2" style={{ background: category?.color, minWidth: "10px", minHeight: "10px" }}></span>
                                            {category?.categoryName}
                                        </div>
                                    );
                                })
                            ) : (
                                <span>After a user fills out the survey, relevant categories will be displayed here</span>
                            )}
                        </div>
                    </div>
                    <div className="fake-border border-none border-left-1 bg-white border-solid border-400 "></div>
                </div>
                <div className="bg-light w-12 md:w-5 pl-0 md:pl-3 pt-4 md:pt-0 pb-3">
                    <MainCard
                        title="Focus Area"
                        subtitle="Improve workplace areas"
                        underline
                        extraClass="m-0 overflow-y-auto max-h-20rem"
                        height="100%"
                        link="focus-area"
                        buttonLable={focusArea ? "View All" : ""}
                    >
                        {isLoading ? (
                            <>
                                <CustomSkelton width="100%" height="3rem" />
                                <CustomSkelton width="100%" height="3rem" />
                            </>
                        ) : focusArea && !Array.isArray(focusArea) ? (
                            <>
                                {Object.keys(focusArea)?.map((data, index) => {
                                    return focusArea[data].length ? (
                                        <div key={index}>
                                            <h4>{data}</h4>
                                            {focusArea[data].map((item, j) => (
                                                <FocusArea
                                                    //    department={item?.departmentName}
                                                    score={item?.overAllScore}
                                                    category={item?.categoryName || null}
                                                    action={item?.recommendation}
                                                    key={j}
                                                    border={index !== focusArea.length - 1}
                                                />
                                            ))}
                                        </div>
                                    ) : null;
                                })}
                            </>
                        ) : (
                            <div className="text-center py-8">
                                <img className="w-7" src={noDataImage} alt="No Data Available" />
                            </div>
                        )}
                    </MainCard>
                </div>
                <div className="w-12 bg-white flex border p-3 py-4">
                    <div className="p-0 pr-3 col-7">
                        <div className="p-3 border h-full">
                            <div className="flex justify-content-between relative">
                                <ProgressFilter display={progressMenu ? "block" : "hidden"} />

                                <h2 className="m-0 text-xl">Progress</h2>
                                <PrimaryButtonOutlined label="Filter" icon="pi pi-filter" iconPos="right" onClick={(event) => setProgressMenu((prev) => !prev)} />
                            </div>
                            {/* <LineChart data={[{ name: "Score", data: progressScores?.filter((score) => score.value !== undefined).map((score) => score.value) || [] }]} /> */}
                            <LineChart
                                data={progressScores?.map((item) => ({ name: item.category, data: item.scores.map((month) => month.count), color: item.color }))}
                                colors={progressScores?.map((item) => item.color)}
                            />
                        </div>
                    </div>
                    <MainCard
                        underline
                        height="100%"
                        extraClass="m-0 col-5 justify-content-center"
                        padding="p-4"
                        title="Departments"
                        buttonIcon="pi pi-plus"
                        onClick={() => navigate("/settings/manage-departments/add")}
                    >
                        <div className="h-18rem overflow-auto">
                            <CustomTable
                                showLines={false}
                                columns={deptColumns}
                                data={deptScores}
                                emptyMessage={() => (
                                    <div className="py-8 text-center">
                                        <h3>No departments to display. Add departments</h3>
                                    </div>
                                )}
                            />
                        </div>
                    </MainCard>
                </div>
            </div>
        </DashboardLayout>
    );
};
