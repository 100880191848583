import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layout";
import { BreadCrumb } from "primereact/breadcrumb";
import { useParams, useNavigate } from "react-router-dom";
import PrimaryButton, { PrimaryButtonOutlined } from "../../components/buttons/button";
import LineChart from "../../components/Charts/LineChart";
import CustomTable from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { departmentScoresByIdAction, focusAreaAction } from "../../redux1/Slices/analyticsSlice";
import { months } from "../../utils/constants";
import { departmentByIdAction } from "../../redux1/Slices/departmentSlice";
import { Menu } from "primereact/menu";
import { getYears } from "./common";
import { categoryAction } from "../../redux1/Slices/categorySlice";
import { ImproveCard } from "../../components/cards/card";
import ImprovementImg from "../../assets/images/improve-img.png";
import { CustomDropDown } from "../../components/input/input";
import Constants from "../../services/constants";
import { getRecommendationColor } from "../../utils/commonFunctions";

export default function DepartmentOverview() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [department, setDepartment] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);

    const yearFilter = useRef(null);
    const catFilter = useRef(null);

    const departmentScores = useSelector((state) => state.analytics.departmentScoresByCat);
    const focusArea = useSelector((state) => state.analytics.focusArea);
    const categories = useSelector((state) => state.category.data);

    const [deptScores, setDeptScores] = useState([]);
    const [monthlyScores, setMonthlyScores] = useState([]);
    const [focusAreaFilter, setFocusAreaFilter] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState("ourSuggestions");

    const attentionTableColumns = [
        { field: "Category", header: "Category", body: "FOCUS_AREA" },
        { field: "Suggestions", header: "Reason for focus", 
          style: { whiteSpace: "normal", wordBreak: "break-word" } },
    ];

    const howToImproveOptions = [
        { field: "ourSuggestions", name: "Our Suggestions" },
        { field: "topThreeServices", name: "Top Three Services" },
        { field: "otherCompanySuggestions", name: "Suggestions from other Companies" },
    ];

    const [catgeoryFilterOptions, setCatgeoryFilterOptions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(catgeoryFilterOptions[0]);

    const scoreColor = (value) => {
        if (value === " " || value === "-") return "#eee";
        else if (value >= 0 && value < 50) return "#FFCECE";
        else if (value >= 50 && value <= 75) return "#FDF4D1";
        else return "#98DFBB ";
    };

    const formatDepartmentScores = (deptScores) => {
        let categories = [];
        let availableCategoryList = [];
        let scores = [];
        Object.keys(deptScores).forEach((item) => {
            deptScores[item].forEach((item) => {
                if (!categories.includes(item.categoryName)) {
                    categories.push(item.categoryName);
                    availableCategoryList.push(item);
                    let data = {
                        category: item.categoryName,
                        color: item.color,
                        scores: months?.map((month) => ({ month: month?.fullName, count: null })),
                    };
                    scores.push(data);
                }
            });
        });

        let updatedScores = scores.map((item) => {
            let scores = item.scores.map((data) => {
                let newData = data;
                if (deptScores[data.month]?.length) {
                    let category = deptScores[data.month].filter((e) => e.categoryName === item.category);

                    newData = category.length
                        ? {
                            month: category[0].month,
                            count: category[0].overAllScore,
                        }
                        : data;
                }
                return newData;
            });

            return { ...item, scores };
        });

        let monthlyScores = months.map((month) =>
            deptScores[month.fullName]
                ? {
                    month: month.shortName,
                    value: Math.round(deptScores[month.fullName]?.reduce((accumulator, currentValue) => accumulator + currentValue.overAllScore, 0) / deptScores[month.fullName].length),
                }
                : { month: month.shortName, value: " " }
        );
        setMonthlyScores(monthlyScores);
        setDeptScores(updatedScores);
        const catFilters = Object.values(updatedScores).map(item => item.category);
        setCatgeoryFilterOptions(catFilters);
        setSelectedCategoryFilter(catFilters[0]);
    };

    useEffect(() => {
        dispatch(categoryAction({ accessToken: localStorage.getItem("authToken") }));
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(focusAreaAction({ id, department: true }));
            dispatch(departmentByIdAction({ data: id })).then((res) => {
                setDepartment({
                    ...res.payload,
                });
            });
        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(departmentScoresByIdAction({ id, year: selectedYear, catId: selectedCategory?._id }));
    }, [id, selectedYear, selectedCategory, dispatch]);

    useEffect(() => {
        formatDepartmentScores(departmentScores);
    }, [departmentScores]);

    const FocusAreaFilter = ({ display = "hidden" }) => {
        return (
            <div className={`w-15rem border bg-white shadow-1 z-5 right-0 top-100 absolute aaa ${display}`}>
                <div className="border-bottom-1 border-gray-300 p-2">
                    <h5 className="mt-0 mb-1 text-base pl-2 font-normal">Filter</h5>
                    <div className="p-2">
                        <CustomDropDown options={catgeoryFilterOptions} optionLabel="" optionValue="" label="Services" placeholder="Category" value={selectedCategoryFilter} onChange={(e) => setSelectedCategoryFilter(e.value)} />
                        <CustomDropDown options={howToImproveOptions} optionLabel="name" optionValue="field" label="Category" value={selectedFilter} onChange={(e) => setSelectedFilter(e.value)} />
                    </div>
                </div>
                {/* 
                <div className="flex align-items-center justify-content-between p-2">
                    <PrimaryButton label="Apply" onClick={(event) => { setSelectedCategoryFilter(catgeoryFilterOptions[0]); setSelectedFilter("ourSuggestions") }}/>
                    <PrimaryButton label="Clear" secondary onClick={(event) => { setSelectedCategoryFilter(catgeoryFilterOptions[0]); setSelectedFilter("ourSuggestions") }} />
                </div> */}
            </div>
        );
    };

    return (
        <DashboardLayout heading={department.name}>
            <BreadCrumb
                model={[{ label: department.name }]}
                home={{
                    label: "Dashboard",
                    template: () => (
                        <span className="cursor-pointer" onClick={() => navigate("/")}>
                            Dashboard
                        </span>
                    ),
                }}
                pt={{
                    root: { className: "bg-transparent border-none p-0 font-semibold " },
                }}
            />
            <div className="my-3  border  bg-white p-3">
                <div className="col-12 flex justify-content-end gap-3 mb-3">
                    <PrimaryButton
                        label="Clear"
                        className="py-1"
                        style={{ background: "var(--color1)", border: "none" }}
                        onClick={() => {
                            setSelectedYear(null);
                            setSelectedCategory(null);
                        }}
                    />
                    <PrimaryButtonOutlined
                        label={selectedYear ? selectedYear : "Select Year"}
                        icon="pi pi-filter"
                        iconPos="right"
                        className="py-1"
                        onClick={(event) => yearFilter.current.toggle(event)}
                    />
                    <Menu
                        // model={getYears('2023'), className: deptId === dept._id ? "bg-skyblue" : "" }))}
                        model={getYears("2023").map((item) => ({ label: item, command: () => setSelectedYear(item), className: selectedYear === item ? "bg-skyblue" : "" }))}
                        popup
                        ref={yearFilter}
                        id="popup_menu_right"
                        popupAlignment="right"
                    />
                    <PrimaryButtonOutlined
                        label={selectedCategory ? selectedCategory.name : "Select Category"}
                        icon="pi pi-filter"
                        iconPos="right"
                        className="py-1"
                        onClick={(event) => catFilter.current.toggle(event)}
                    />
                    <Menu
                        // model={getYears('2023'), className: deptId === dept._id ? "bg-skyblue" : "" }))}
                        model={categories.map((item) => ({ label: item.name, command: () => setSelectedCategory(item), className: selectedCategory === item ? "bg-skyblue" : "" }))}
                        popup
                        ref={catFilter}
                        id="popup_menu_right"
                        popupAlignment="right"
                    />
                </div>
                <div className="flex flex-wrap ">
                    <div className="col-12 lg:col-7 p-0 lg:pr-3 mb-3 lg:mb-0 align-self-stretch">
                        <div className="border bg-white p-3 h-full">
                            <h2 className="m-0 text-xl pl-2">Score over Time</h2>
                            <LineChart
                                data={deptScores?.map((item) => ({ name: item.category, data: item.scores.map((month) => month.count), color: item.color }))}
                                colors={deptScores?.map((item) => item.color)}
                            />
                        </div>
                    </div>
                    <div className="col-12 lg:col-5 border p-3 bg-white">
                        <h2 className="mt-0 mb-3 text-xl pl-2">Score</h2>
                        <div className="border flex flex-wrap">
                            {monthlyScores?.map((dept, index) => {
                                return (
                                    <div className="col-3 text-center p-3" key={index}>
                                        <h3 className="mt-0">{dept.month}</h3>
                                        <h3 style={{ width: "43px", height: "43px", background: getRecommendationColor(dept.value).background }} className={` inline-block text-lg font-normal border p-2 m-auto `}>
                                            {dept.value}
                                        </h3>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-12 lg:12 mb-3">
                <div className="bg-white border p-3">
                    <h2 className="mt-0 mb-3 text-xl pl-2">Focus areas</h2>
                    <div className=" ">
                        <CustomTable
                            extraClassName="focus-table"
                            columns={attentionTableColumns}
                            data={
                                focusArea && Object.keys(focusArea).length
                                    ? focusArea[Object.keys(focusArea)[0]].map((item) => ({ Category: item.categoryName, Suggestions: item.lowScoreSuggestion, score: item.overAllScore || "" }))
                                    : []
                            }
                            showLines={false}
                        />
                    </div>
                </div>
            </div>
            <div className="w-12 lg:12 bg-white border">
                <div className={`p-5 mb-5 `}>
                    <div className="flex align-items-center justify-content-between relative">
                        <h2 className="mt-0 mb-3 text-xl pl-2">How to improve</h2>
                        <div className="flex align-items-center gap-3 justify-content-end mb-2">
                            <FocusAreaFilter display={focusAreaFilter ? "block" : "hidden"} />
                            <PrimaryButtonOutlined label="Filter" icon="pi pi-filter" iconPos="right" onClick={(event) => setFocusAreaFilter((prev) => !prev)} />
                        </div>
                    </div>
                    <div className="border p-5 ">
                        {focusArea && Object.values(focusArea).length ?
                            Object.values(focusArea)[0].filter(item => item.categoryName === selectedCategoryFilter)[0]?.howToImprove?.[selectedFilter]?.map(item => {
                                return (
                                    <ImproveCard
                                        id={item?._id}
                                        category={item?.category?._id}
                                        src={item.suggestionIcon ? Constants.BACKEND_URL + item.suggestionIcon : item.icon ? Constants.BACKEND_URL + item.icon : ""}
                                        title={item?.title ? item?.title : item?.name ? item?.name : ""}
                                        selectedFilter={selectedFilter}
                                        data={item?.description}
                                        navigate={navigate}
                                    />
                                )
                            })
                            : null}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}